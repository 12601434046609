/* @import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap');


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* .AppBanner-Two{
  background-color: white !important;
} */

/* top header  css  */
:root {
  --bar-color: #fff;
  --ball-color: #fff;
}

/* Loader Styles */

.loaderContainer {
  height: 100vh;
  background: #2b9229;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  position: fixed;
  overflow: hidden;
  /* background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9); */
  transition: 0.5s;
}

.loader {
  position: relative;
  width: 75px;
  height: 100px;
}

.loader_bar {
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 50%;
  background: #fff;
  transform-origin: center bottom;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.loader_bar:nth-child(1) {
  left: 0;
  transform: scale(1, 0.2);
  animation: barUp1 4s infinite;
}

.loader_bar:nth-child(2) {
  left: 15px;
  transform: scale(1, 0.4);
  animation: barUp2 4s infinite;
}

.loader_bar:nth-child(3) {
  left: 30px;
  transform: scale(1, 0.6);
  animation: barUp3 4s infinite;
}

.loader_bar:nth-child(4) {
  left: 45px;
  transform: scale(1, 0.8);
  animation: barUp4 4s infinite;
}
.selecteproUser-img{
  position: relative;
  overflow: visible !important;
}
.licensebadge{
  color: #2b9229;
  width: 30px;
  height: 30px;
  border-radius: 50% ;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: white;
}
.licensebadge2{
  color: #2b9229;
  width: 30px;
  height: 30px;
  border-radius: 50% ;
  position: absolute;
  bottom: 20px;
  right: 30px;
  background-color: white;
}
.licensebadge img{
  width: 100% ;
  height: 100% ;
  border-radius: 50%;

}
.loader_bar:nth-child(5) {
  left: 60px;
  transform: scale(1, 1);
  animation: barUp5 4s infinite;
}

.loader_ball {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  animation: ball 4s infinite;
}

/* Keyframes */
@keyframes ball {
  0% {
    transform: translate(0, 0);
  }

  5% {
    transform: translate(8px, -14px);
  }

  10% {
    transform: translate(15px, -10px);
  }

  17% {
    transform: translate(23px, -24px);
  }

  20% {
    transform: translate(30px, -20px);
  }

  27% {
    transform: translate(38px, -34px);
  }

  30% {
    transform: translate(45px, -30px);
  }

  37% {
    transform: translate(53px, -44px);
  }

  40% {
    transform: translate(60px, -40px);
  }

  50% {
    transform: translate(60px, 0);
  }

  57% {
    transform: translate(53px, -14px);
  }

  60% {
    transform: translate(45px, -10px);
  }

  67% {
    transform: translate(37px, -24px);
  }

  70% {
    transform: translate(30px, -20px);
  }

  77% {
    transform: translate(22px, -34px);
  }

  80% {
    transform: translate(15px, -30px);
  }

  87% {
    transform: translate(7px, -44px);
  }

  90% {
    transform: translate(0, -40px);
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes barUp1 {
  0% {
    transform: scale(1, 0.2);
  }

  40% {
    transform: scale(1, 0.2);
  }

  50% {
    transform: scale(1, 1);
  }

  90% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 0.2);
  }
}

@keyframes barUp2 {
  0% {
    transform: scale(1, 0.4);
  }

  40% {
    transform: scale(1, 0.4);
  }

  50% {
    transform: scale(1, 0.8);
  }

  90% {
    transform: scale(1, 0.8);
  }

  100% {
    transform: scale(1, 0.4);
  }
}

@keyframes barUp3 {
  0% {
    transform: scale(1, 0.6);
  }

  100% {
    transform: scale(1, 0.6);
  }
}

@keyframes barUp4 {
  0% {
    transform: scale(1, 0.8);
  }

  40% {
    transform: scale(1, 0.8);
  }

  50% {
    transform: scale(1, 0.4);
  }

  90% {
    transform: scale(1, 0.4);
  }

  100% {
    transform: scale(1, 0.8);
  }
}

@keyframes barUp5 {
  0% {
    transform: scale(1, 1);
  }

  40% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.2);
  }

  90% {
    transform: scale(1, 0.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

a {
  text-decoration: none;
  color: unset;
}

.top-header-app {
  display: flex;
  width: 100%;
  background-color: #418e30;
  padding: 5px 10px;
  justify-content: flex-end;
}

.top-header-main {
  display: flex;
  align-items: center;
  color: #fff;
}

.top-header-main .hdt {
  padding: 10px 15px;
  border-right: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.top-box-select {
  border-right: 1px solid #fff;
  padding: 7px 15px;
}

.top-box-select .ant-select-arrow svg {
  fill: #fff;
}

.Custom-top-header-select {
  color: #fff !important;
}

.Custom-top-header-select2 {
  color: #fff !important;
  display: none;
}

.Custom-top-header-select2 .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}

.Custom-top-header-select .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
  color: #fff !important;
}

/* app header  */

.app-header-main-section {
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
}

.weblogo {
  width: 82px;
  height: 82px;
}

.weblogo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.app-header-content {
  display: flex;
  align-items: center;
}

.header-section-pages {
  display: flex;
  align-items: center;
}

.header-section-pages-each {
  padding: 10px;
  margin: 0px 19px;
}

.header-section-pages-each a {
  text-decoration: none;
  color: #c7c7c7;
  font-size: 18px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
}

.routeActive {
  border-bottom: 2px solid #418e30;
}

.routeActive a {
  color: #418e30 !important;
}

.header-main-btns {
  display: flex;
  align-items: center;
  gap: 5px;
}
.footercontentdm {
  width: 80% !important;
  font-size: 16px !important;
}
.paysubdiv{
  width: 50% !important;
}
.header-main-btns .hd-btns {
  padding: 10px !important;
  width: 100px !important;
  background: #418e30 !important;
  border-radius: 30px;
  color: #fff !important;
}

.header-main-btns .logout {
  padding: 0px !important;
  width: 30px !important;
  /* background: #418E30 !important; */
  border-radius: 100%;
  height: 62px;
}

/* .ant-dropdown {
  inset: unset;
  inset: 150px auto auto 1454.41px;
} */

.ant-dropdown-menu li {
  border-bottom: 1px solid #fff;
  margin-bottom: 10px !important;
  border-radius: unset !important;
}

.ant-dropdown .ant-dropdown-menu {
  min-width: 150px;
  right: 10px;
}

/* Res web  */

.res-header-web {
  width: 100%;
  background: #418e30 !important;
  display: none;
  padding: 10px;
}

.ant-drawer-content-wrapper {
  width: 249px !important;
  /* background-color: #418E30; */
}

.ant-drawer-wrapper-body {
  background-color: #000000;
}

.ant-drawer-body {
  padding: 23px 11px;
}

.res-routes {
  /* width: 100% !important; */
  /* background: #418E30 !important; */
  margin: 10px 0;
  /* padding: 10px; */
  border-radius: 10px;
  text-decoration: none;
  color: #000000 !important;
  font-size: 18px;
  font-family: "Lato", sans-serif !important;
  font-weight: 800 !important;
  text-transform: uppercase;
}

/* .res-routes:hover{
  color: #418E30 !important;
} */

.Activeroute {
  color: #418e30 !important;
}

.Activeroute:hover {
  color: #306023 !important;
}

.ant-drawer-body {
  background-color: white !important;
}

.ant-drawer-header {
  background-color: white !important;
}

.res-routescontainer {
  width: 100% !important;
}

.drawer-cross {
  background-color: green;
  font-size: 35px;
  padding: 9px;
  border-radius: 10px;
}

/* .res-routes a {
  width: 100% !important;
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
} */

/* all banners Css  */
.allbanners_main {
  width: 100%;
  height: 400px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner_inner_text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner_inner_text h2 {
  font-size: 40px;
  color: #fff;
  font-family: "Krona One", sans-serif;
  text-transform: uppercase;
  text-align: center;
}

.banner_inner_text .line {
  width: 270px;
  border: 1px solid #418e30;
  margin: 15px 0;
}

.banner_inner_text h3 {
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.banner_inner_text p {
  color: #fff;
}

.Card_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.CardMain {
  padding: 29px 25px 20px 25px;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1); */
  box-shadow: 0.5px 4px 5px 1px #418e30 !important;
  border-radius: 40px;
}

.card_header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background: #f3f3f3;
  border-radius: 10px;
  min-width: 330px;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* .card_header button {
  border: none;
  padding: 3px 15px;
  background: #418E30;
  color: #fff;
  border-radius: 5px;
} */
.card_header div {
  width: 100%;
}

.card_header button {
  /* background-color: #2B9229 !important; */
  width: 100%;
}

.eachitem {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #f3f3f3;
  border-radius: 10px;
  min-width: 392px;
  margin-bottom: 20px;
}
.custom-class {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #f3f3f3;
  border-radius: 10px;
  min-width: 392px;
  margin-bottom: 20px;
}

.menu-flags-custom {
  padding-bottom: 0 !important;
  width: 100%;
}

.menu-flags-custom button {
  border: none !important;
  padding: 5px 10px 5px 0px !important;
}

.menu-flags-custom button span {
  color: #898484;
}

.error-message {
  padding-left: 10px !important;
  margin-top: -15px;
  font-size: 14px;
  color: #ce3030;
  max-width: 389px;
}

.eachitem input,
.custom-class input {
  border: none;
  background: transparent;
  outline: none;
  width: 100%;
}

.eachitem input::placeholder,
.custom-class input::placeholder {
  color: #898484;
}

.eachitem input:focus-visible,
.custom-class input:focus-visible {
  border: none;
  background: transparent;
  outline: none;
}

.remember {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.remember span,
.remember label {
  font-size: 14px;
  color: #2b9229;
}

.CardMain .logind {
  display: flex;
  justify-content: center;
  width: 100%;
}

.CardMain .loginbtn {
  width: 80%;
  background-color: #2b9229 !important;
  margin-bottom: 10px;
}

.CardMain .loginbtnout {
  width: 80%;
  background-color: #c4c4c4 !important;
}

.loginwith h6 {
  font-size: 14px;
  font-family: "Mulish", sans-serif;
}

.lgwiic {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  background-color: #a8b0af;
}

.lgwiic svg {
  width: 100%;
  height: 100%;
}

.footer_container {
  background-color: #f9f9f9;
  margin-top: 50px;
  padding: 25px 15px;
}

.footer_container_Copyrighted {
  padding: 12px 0px;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_container_Copyrighted h3 {
  color: #418e30;
  font-size: 13px !important;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}

.ftimg {
  width: 96px;
  height: 100px;
  padding: 5px;
}

.ftimg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cl1 p {
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  line-height: 17.5px;
}
.popularsubscription{
  width: 200px !important;
  border:2px solid #418E30;
  border-radius:15px
}
.cl2 h3 {
  font-size: 15px;
  color: #418e30;
  text-transform: uppercase;
  font-family: "Krona One", sans-serif;
}

.cl2 ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.cl2 ul li {
  margin: 8px 0;
}

.ft-inp {
  width: 100%;
  background-color: #d9d9d9;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 7px;
}

.ft-inp input {
  width: 100%;
  outline: none;
  border: none;
  background: transparent;
}

.ft-inp span {
  background-color: rgb(65, 142, 48);
  width: 25px;
  height: 26px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ft-inp input:focus-visible {
  outline: none;
  border: none;
}

.cardtwomain {
  padding: 29px 25px 20px 25px;
  box-shadow: 0.5px 4px 5px 1px #418e30 !important;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 500px;
}

.veri-image {
  width: 200px;
  height: 186px;
}

.veri-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.num_veri {
  width: 30px;
  border: 1px solid #000000;
  margin: 0px 2px;
}

.num_verification input {
  padding: 10px !important;
  outline: none !important;
  width: 3em !important;
  border: none;
  border-bottom: 2px solid #000000;
}

.num_verification input[type="number"]::-webkit-inner-spin-button,
.num_verification input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.veribtn {
  width: 80%;
}

.veribtncnf {
  width: 100%;
  background-color: #418e30 !important;
}

.disabled-span {
  display: none;
}

.accountimg {
  width: 259px;
  height: 229px;
}

.accountimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.acountbtn {
  background: #252525;
  width: 15%;
  height: 64px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.acountbtn button {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

/* contact us   */
.maincontact {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}

.contactimg {
  width: 50%;
}

.contactimg img {
  width: 100%;
  object-fit: cover;
}

.contact_form {
  padding: 10px;
  width: 50%;
}

.contact_form h3 {
  color: #418e30;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
}

.contact_form h2 {
  color: #282c4b;
  font-size: 36px;
  font-weight: 700;
  font-family: "Ubuntu", sans-serif;
}

.contact_form form {
  margin-top: 20px;
}

.contact_form label {
  display: block;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
  color: #14183e;
}

.contact_form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f8fff9;
  outline: none;
  border: none;
  font-size: medium;
  font-weight: unset;
  margin-top: 10px;
  font-weight: 500;
  font-family: "Lato", sans-serif;
}

.contact_form input::placeholder,
.contact_form textarea::placeholder {
  color: #747582;
  font-size: 15px;
  font-weight: unset;
  font-family: unset;
}

.contact_form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f8fff9;
  outline: none;
  border: none;
  font-size: medium;
  font-weight: unset;
  max-height: 100px;
  height: 100px;
  margin-top: 10px;
}

.contactbtn {
  background-color: #418e30 !important;
  text-transform: none !important;
  border-radius: 30px !important;
  padding: 15px 30px !important;
}

/* add produt  */

.productmain {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 50px;
  padding: 20px 30px;
  width: 100%;
  overflow: hidden;
}

.productcardmain {
  width: 70%;
  box-shadow: 0.5px 4px 5px 1px #418e30 !important;
  padding: 30px 50px 50px 50px;
  border-radius: 30px;
  height: fit-content;
  background-color: #fcfbfb;
}
.backgroundImage .slick-slide {
    margin: 0px !important;
    height: 500px !important;
}
.lftheader div {
  width: 124px;
  height: 124px;
  border-radius: 100px;
  border: 1px solid #2b9229;
}

.profile-upload-container {
  position: relative;
}

.up-insert {
  width: unset !important;
  height: unset !important;
  border-radius: unset !important;
  overflow: unset !important;
  border: unset !important;
  position: absolute;
  bottom: 2px;
  right: 3px;
  width: 30px !important;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px !important;
  background: #002055;
}

.lftheader div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100px;
}

.lftheader span {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #002055;
  height: fit-content;
}

.pg-cards .ant-pagination-item-active {
  border-color: #2b9229 !important;
}

.lftheader {
  margin: 15px 0;
}

.productcardmain form .each-input {
  width: 50%;
}
.each-input2 {
  width: 100% !important;
}
.productcardmain form .each-input .ant-select {
  width: 100%;
  height: 47px;
}
.productcardmain form .each-input2 .ant-select {
  width: 100%;
  height: 47px;
}

.productcardmain form .each-input .ant-select-selector {
  border-color: #2b9229 !important;
}
.productcardmain form .each-input2 .ant-select-selector {
  border-color: #2b9229 !important;
}

.Category_search_cat
  :where(.css-dev-only-do-not-override-2q8sxy).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #418e30 !important;
  color: #fff;
}

.CategoriesBanner .ant-select-selector {
  border-color: #2b9229 !important;
  box-shadow: 0 0 0 2px rgb(90 255 5 / 10%) !important;
}

.categoryOne .ant-select {
  height: 47px !important;
}

.Category_search_cat .ant-select-selector {
  border-color: #2b9229 !important;
  background-color: #418e30 !important;
  color: #fff !important;
}

.Category_search_cat .ant-select-arrow svg {
  fill: #fff !important;
}

.Category_search_cat .ant-select .ant-select-selection-placeholder {
  color: #fff !important;
}

.Category_search_cat .ant-select-item-option-selected {
  background-color: #418e30 !important;
}

.Category_search_Sub_cat .ant-select-selector {
  border-color: #2b9229 !important;
  /* background-color: #418E30 !important; */
  color: #002055 !important;
}

.Category_search_Sub_cat .ant-select-item-option-selected {
  background-color: #418e30 !important;
}

.Category_search_Sub_cat .ant-select-item-option-active {
  background-color: #418e30 !important;
}

.Category_search_Sub_cat .ant-select .ant-select-selection-placeholder {
  color: #000 !important;
}

.Category_search_Sub_cat
  .ant-select-selector
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #418e30 !important;
}

.productcardmain form .each-input input {
  width: 100%;
  outline: none;
  border: 1px solid #e9f1ff;
  padding: 10px;
  border-radius: 15px;
}
.productcardmain form .each-input2 input {
  width: 100%;
  outline: none;
  border: 1px solid #e9f1ff;
  padding: 10px;
  border-radius: 15px;
}

.productcardmain form textarea {
  width: 100%;
  border-radius: 15px;
  border: 1px solid #e9f1ff;
  padding: 10px;
  min-height: 100px;
  outline: none;
}

.productcardmain form .each-input button {
  width: 100%;
  border-radius: 15px !important;
  background-color: #418e30 !important;
  padding: 10px !important;
}

.productcardmain form button {
  width: 50%;
  border-radius: 15px !important;
  background-color: #418e30 !important;
  padding: 10px !important;
}

.productcardtwo {
  width: 25%;
}

.productcardtwo :first div {
  margin-top: 50px;
}

.productcardtwo h3 {
  color: #418e30;
  font-size: 20px;
  font-family: "Krona One", sans-serif;
  text-transform: uppercase;
}

.productcardtwo .line {
  width: 100%;
  border-bottom: 2px solid #d9d9d9;
  margin: 20px 0;
}

.prd2each {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.prd2each span {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  color: #000000;
  margin-top: 7px;
}

.prd2each_main {
  overflow-y: scroll;
  max-height: 500px;
}

.prd2each_main::-webkit-scrollbar {
  width: 3px;
}

.prd2each_main::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  height: 5px;
}

/* For Firefox */
.prd2each_main {
  scrollbar-width: thin;
  /* "auto" or "thin" based on your preference */
  scrollbar-color: #d9d9d9 #ecf0f1;
  /* Set thumb and track colors */
}

.productcard2img {
  width: 138.33px;
  height: 196.43px;
  border-radius: 30px;
  overflow: hidden;
}

.productcard2img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fetchingLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.fetchingLoader p {
  margin-top: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

.fetchingLoader img {
  width: 12%;
}

.userprofilebtnal {
  width: 20% !important;
  height: fit-content !important;
  padding: 5px !important;
  background-color: #418e30 !important;
}

.profileimg-bd {
  border: 2px solid #418e30;
  border-radius: 100%;
  width: 130px !important;
  height: 130px !important;
}

/* andt rating css  */
.sldmain {
  width: 50%;
}

.ant-slider-track {
  background-color: #418e30 !important;
  height: 14px !important;
  border-radius: 10px 5px 5px 10px !important;
}

.ant-slider-rail {
  height: 14px !important;
  border-radius: 10px 5px 5px 10px !important;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-slider
  .ant-slider-handle::after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 16px !important;
  height: 16px !important;
  background-color: #ffffff;
  box-shadow: 0 0 0 2px #418e30 !important;
  border-radius: 50%;
  cursor: pointer;
  transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s,
    height 0.2s, box-shadow 0.2s;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-slider
  .ant-slider-handle:focus::before {
  inset-inline-start: 0 !important;
  inset-block-start: 0 !important;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-slider-horizontal
  .ant-slider-handle {
  inset-block-start: 3px !important;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-slider .ant-slider-handle {
  width: 16px !important;
  height: 16px !important;
}

.ant-slider-mark {
  top: 19px !important;
}

.ant-slider-dot {
  display: none !important;
}

.ant-slider-dot-active {
  border-color: #418e30 !important;
}

/* recommander product css  */

.recommanded_product {
  width: 100% !important;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.recommanded_products_img {
  width: 100% !important;
  height: 300px;
}

.recommanded_products_img img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.recommanded_products_name {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.recommanded_products_name p {
  text-align: center;
  margin: 0;
  padding: 5px 0px;
  font-size: 22px;
  color: #fff;
  font-family: "Krona One", sans-serif;
  background-color: #418e30;
}

/* review card css  */

.ReviewEach {
  min-height: 200px;
  max-height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* Customize the scrollbar */
.review_para::-webkit-scrollbar {
  width: 2px;
  /* Set the width of the vertical scrollbar */
  height: 5px;
  /* Set the height of the horizontal scrollbar */
}

.review_para::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Set the background color of the scrollbar track */
}

.review_para::-webkit-scrollbar-thumb {
  background: #cac9c9;
  /* Set the color of the scrollbar thumb */
  border-radius: 5px;
  /* Round the corners of the scrollbar thumb */
}

/* Optionally, you can also style the scrollbar corner (the intersection of vertical and horizontal scrollbars) */
.review_para::-webkit-scrollbar-corner {
  background: #f1f1f1;
  /* Set the color of the scrollbar corner */
}

.reviewcard_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reviewuser_info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.review_para p {
  color: #546e7a;
  margin-left: 5px !important;
  margin: 0;
  padding: 0 3px;
  font-family: "Calibre Test", sans-serif;
}

.review_para {
  width: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.reviewdate span {
  color: #90a4ae;
  font-family: "Calibre Test", sans-serif;
  font-size: 14px;
}

/* slider css   */

.NewArrival_main {
  padding: 45px 0;
}

.NewArrival {
  display: flex;
  align-items: center;
  background-color: #d9d9d9;
  padding: 10px 0;
}

.NewArrival .button {
  background-color: #418e30 !important;
  color: white;
  border-radius: 20px;
  padding: 4px;
  font-size: 15px;
  font-weight: bold;
  width: 190px;
  text-align: center;
  text-transform: inherit;
  box-shadow: 0px 3px 4px #418e30;
}

.NewArrival .Slickbtn {
  width: 100px !important;
}

.sectionSlider {
  width: 70%;
  padding: 0 10px;
}

.NewArrivalresponsive {
  display: none !important;
}

.arivalheadings {
  width: 30%;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.arivalheadings p,
.NewArrivalresponsive p {
  color: #418e30;
  font-family: "Krona One", sans-serif;
  font-weight: 400;
}

.arivalheadings h3,
.NewArrivalresponsive h3 {
  font-family: "Krona One", sans-serif;
  font-size: 27px !important;
}

.NewArrivalresponsive {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  margin: 0 20px;
}

.productimg_slide {
  height: 200px;
  width: 100%;
  border: 2px solid #418e30;
  border-radius: 10px;
  overflow: hidden;
  max-width: 275px;
}

.productimg_slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product_description_slide {
  padding: 10px;
  font-family: "Inter", sans-serif;
  /* max-height: 59px; */
  overflow-y: auto;
}

.product_description_slide h6 {
  margin-bottom: 0;
}

.product_description_slide p {
  margin-bottom: 0;
  word-wrap: break-word;
}

.sectionSlider .slick-prev,
.sectionSlider .slick-next {
  top: 40% !important;
  background-color: #2b9229 !important;
  color: #fff !important;
  border-radius: 100%;
}

.NewArrival .slick-prev {
  left: -10px !important;
}

.NewArrival .slick-next {
  right: -10px !important;
}

/* why chooose css  */

.whychoosebanner_border {
  border: 2px solid #fff;
  width: 360px;
  height: 481px;
  border-radius: 20px;
  position: relative;
}

.whychoosebanner_img {
  position: absolute;
  width: 100%;
  height: 90%;
  top: 6%;
  right: -26%;
}

.whychoosebanner_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

/* merchant profile   */

.MerchantProfileViewer {
  background-color: black;
  width: 100%;
}

.MerchantProfileViewer .row {
  padding: 10px;
}

.merchantprofile_IMGContainer {
  width: 290px;
  height: 290px;
  border: 3px solid #2b9229;
  border-radius: 50% !important;
  overflow: visible !important;
  position: relative;
}

.merchantprofile_IMGContainer img {
  width: 100%;
  height: 100%;
  object-fit: fill;
  transition: 0.5s ease;
  backface-visibility: hidden;
  opacity: 1;
  border-radius: 50% !important;
}

.merchantprofle_details .merchantprofile_heading h1 {
  font-family: "Manjari", sans-serif;
  color: #418e30;
}

.merchantprofle_details {
  font-family: "Manjari", sans-serif;
  color: #fff;
}

.merchantprofile_description h4 {
  color: #fff;
  font-family: "Manjari", sans-serif;
}
.merchantServicesCards h1 {
  position: relative;
  display: inline-block; /* Ensure h1 element has an inline size */
}
.merchantprofile_heading h1{
  font-weight: bolder !important;
  color: #418e30 !important;
}
.merchantServicesCards h1::after {
  content: '';
  position: absolute;
  width: 100%; /* Make the width of the underline match the text width */
  background-color: #2b9229;
  height: 2px;
  left: 0; /* Start from the left edge */
  bottom: -2px; /* Adjust position slightly below the text */
}

.merchantprofile_description p {
  color: #fff;
}

.merchantServicesCards {
  padding: 30px 0 10px 0;
}

.merchantServicesCards h3 {
  font-family: "Krona One", sans-serif;
}

.marchantservices_eachCard {
  width: 100%;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 10px;
  overflow: hidden;
}

.merchant_eachproductimg {
  position: relative;
  width: 100%;
  height: 276px;
}

.merchant_eachproductimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Advertisements-container h1 {
  font-family: "Krona One", sans-serif;
}

.Advertisements-container-slider img {
  height: 500px;
  width: 100%;
  cursor: pointer;
}

.Advertisements-container-slider .slick-slide {
  margin: 0 !important;
}

.marchantservices_eachCard_details {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
  background: rgb(0, 0, 0) !important;
  background: rgba(0, 0, 0, 0.5) !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 5px;
}

.merchant_eachproductimg img:hover {
  opacity: 0.7;
}

.merchant_eachproductimg:hover .marchantservices_eachCard_details {
  opacity: 2;
}

.marchantservices_eachCard_details h2 {
  width: 100%;
  font-size: 25px;
  color: #3bd938;
  font-family: "Manjari", sans-serif;
  font-weight: bold;
}

.marchantservices_eachCard_details p {
  color: #fff;
  font-family: "Manjari", sans-serif;
}

@media screen and (min-width: 768px) {
  .merchantServicesCards .col-md-3 {
    flex: 0 0 auto;
    width: 29%;
  }
}

@media screen and (max-width: 500px) {
  .top-header-app {
    display: none;
  }

  .Custom-top-header-select2 {
    display: flex;
  }
  .paymentbtndiv{
    flex-direction: column-reverse;
    align-items: flex-start;
  }
}

@media screen and (max-width: 1000px) {
  .Featured_Cards {
    flex-wrap: wrap;
  }

  .Featured_Cards .Featured_Cards_each {
    width: 45%;
    margin-bottom: 27px;
  }
}

@media screen and (max-width: 600px) {
  .allbanners_main {
    height: 300px;
  }
  .banner_div h1 span{
    font-size: 15px !important;
  }
  .banner_div p span{
    font-size: 10px !important;
  }
  .CardMain {
    padding: 29px 10px 10px 10px;
  }

  .eachitem {
    min-width: 280px;
  }

  .banner_inner_text h2 {
    font-size: 35px;
  }

  .cardtwomain {
    min-width: 332px;
  }

  .acountbtn {
    width: 24%;
  }
  .advertisements-con{
    height: 50% !important;
  }
  .advertisements-con img{
    height: 100% !important;
  }

  .contact_form,
  .contactimg {
    width: 100%;
  }

  .each-input {
    width: 100% !important;
  }

  .res-productcardmain {
    flex-wrap: wrap;
  }

  .productcardmain form button {
    width: 100%;
  }

  .userprofilebtnal {
    width: 45% !important;
  }

  .rating {
    padding: 0 10px;
  }

  .ratCard_BackGrnd {
    margin-top: 17px;
    padding: 30px;
    padding: 20px;
    width: 100%;
  }

  .NewArrival .slick-next,
  .NewArrival .slick-prev {
    display: none !important;
  }

  .home_webbanner_text h1 {
    font-family: "Krona One", sans-serif;
    font-size: 16px !important;
  }

  .home_webbanner_banner .btn {
    width: 140px !important;
    font-size: 12px !important;
  }

  .category_heading h1 {
    font-size: 18px !important;
  }

  .whychoosebanner_img {
    right: -8%;
  }

  .footer_container_Copyrighted h3 {
    font-size: 10px !important;
  }

  .Featured_Cards .Featured_Cards_each {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .ChangeEmailCom-div .button-input {
    width: 60% !important;
  }
  .flex-direction-column {
    flex-direction: column;
  }
  .backgroundImage {
    height: 330px;
  }
  .ChangeEmailCom,
  .NotificationCom {
    width: 96% !important;
  }
  .main-slide-bar {
    width: 96% !important;
  }
  .footercontentdm {
    width: 100% !important;
  }
  .cardtick{
    display: none !important;
  }
  .res-header-web {
    display: flex;
    background: #418e30 !important;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .res-header-web .ant-select-arrow svg {
    fill: #fff;
  }
  .paymentbtndiv{
        width: 90% !important;
  }
  .app-header-main-section {
    display: none;
  }

  .contact_form h2 {
    font-size: 27px;
  }

  .productmain {
    padding: 20px 15px;
  }

  .productcardmain {
    padding: 30px 10px 50px 10px;
  }

  .productmain {
    flex-wrap: wrap;
  }

  .productcardmain {
    width: 100%;
  }
  .paymentcarddiv {
    width: 90% !important;
    margin: 0px 20px;
  }
  .productcardtwo {
    width: 100%;
  }

  .sldmain {
    width: 100%;
  }

  .aboutusbtn {
    width: 41%;
  }

  .about_inpt_main {
    padding: 5px;
  }

  .ReviewEach {
    border: none !important;
    margin-top: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    border-radius: 7px;
  }

  .backGround_PpleComt .row {
    margin-top: 3px !important;
  }

  .NewArrival {
    flex-wrap: wrap;
  }

  .sectionSlider {
    width: 100%;
  }

  .arivalheadings {
    display: none;
  }

  .NewArrivalresponsive {
    display: flex !important;
  }

  .NewArrivalresponsive h3 {
    font-size: 40px;
  }

  .home_webbanner_banner .btn {
    width: 140px !important;
    font-size: 15px !important;
  }

  .category_heading h1 {
    font-size: 27px;
  }

  .category_Cards_container {
    gap: 10px;
  }

  .categorydiv_one_box1,
  .categorydiv_one_box2,
  .categorydiv_one_box3,
  .cat-45-home {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px !important;
  }

  .whychoosebanner_content {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
  }

  .Featured_Cards .Featured_Cards_each {
    width: 78%;
  }

  .Featured_Cards_merchantimg {
    width: 249px !important;
  }

  .buyproductimg_container {
    margin-bottom: 17px;
  }

  .recommanded_product_cards {
    gap: 10px !important;
  }

  .merchantprofile_heading {
    margin-top: 20px;
  }

  .category .categoryTwo {
    max-height: unset;
  }

  .fetchingLoader {
    margin-top: 10px;
  }

  .categorycardsimg {
    height: 250px;
  }
}

@media screen and (max-width: 900px) {
  .cl2 h3 {
    font-size: 12px;
  }

  .header-section-pages-each {
    margin: unset !important;
  }

  .NewArrival .button {
    width: 140px;
    margin-bottom: 19px !important;
  }

  .arivalheadings h3 {
    font-size: 24px;
  }

  .home_categoryeach {
    padding: 9px 6px;
  }

  .home_webbanner_text h1 {
    font-family: "Krona One", sans-serif;
    font-size: 20px;
  }

  .home_webbanner_text p {
    font-family: "Lato", sans-serif;
  }

  .home_webbanner_text .h5 {
    font-family: "Krona One", sans-serif !important;
    color: #418e30;
  }

  .home_webbanner_text h6 {
    font-family: "Lato", sans-serif;
  }

  .home_webbanner_banner .btn {
    width: 160px !important;
  }

  .home_categoryeach h5 {
    font-size: 15px;
    font-weight: 600;
  }

  .whychoosebanner_border {
    height: 425px;
  }
}

@media screen and (max-width: 1024px) {
  .whychoosebanner_border {
    height: 372px;
    width: 270px;
  }

  .home_subcat_search {
    font-size: 10px;
    padding: 3px 3px;
  }

  .home_categoryeach {
    padding: 11px 4px;
  }

  .category .categoryOne {
    padding: 56px 13px 46px 13px !important;
  }

  .Featured_Cards_merchantimg {
    width: 221px;
  }

  .Featured_Cards_merchant_bio span {
    font-size: 15px;
  }

  .AllMedia .social_Media {
    width: 83px;
  }

  .buyproductimg_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .selectedproductimg {
    width: 272px;
    height: 277px;
  }

  .recommanded_product_cards {
    gap: 22px !important;
  }
}

/* 
@media screen and (max-width: 1182px) {
  .Featured_Cards_merchantimg {
    width: 207px;
  }
} */

@media screen and (min-width: 576px) {
  .container {
    max-width: 555px !important;
  }
}

@media screen and (min-width: 768px) {
  .container {
    max-width: 769px !important;
  }
}
@media screen and (max-width: 768px) {
  .home_webbanner_banner{
    top: 50% !important;
  }
  .backgroundImage .slick-slide {
    margin: 0px !important;
    height: 330px !important;
}
}
@media screen and (max-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
  .paysubdiv{
    width: 75% !important;
  }
}
@media screen and (min-width: 992px) {
  .container {
    max-width: 1000px !important;
  }
}
@media screen and (max-width: 992px) {
  .footerappimage {
    flex-direction: column !important;
  }
  .paysubdiv{
    width: 100% !important;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1140px !important;
  }
 
}


@media screen and (min-width: 1400px) {
  .container {
    /* max-width: 1320px !important;   default    */
    max-width: 1365px !important;
  }
}

.heart-main img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%)
    hue-rotate(0deg);
}
.NotificationCom {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  background: #fcfbfb;
  border-radius: 20px;
  box-shadow: 4px 2px 5px 0px #418e30;
  height: 704px;
}

.NotificationCom .green-color {
  font-size: 20px;
  font-family: manjari;
  color: #418e30;
}
.Notification-Buttons {
  background-color: #418e30;
  border-radius: 20px;
  width: 334px;
}
.Notification-Buttons .button-1 {
  border: 0;
  font-family: work sons;
  height: 41px;
  width: 54%;
  border-radius: 20px;
  background: #c7c7c7;
  font-size: 13px;
  font-weight: bold;
}
.Notification-Buttons .button-2 {
  border: 0;
  font-family: work sons;
  width: 44%;
  border-radius: 20px;
  height: 41px;
  background-color: #418e30;
  color: white;
  font-size: 13px;
  font-weight: bold;
}
.main-slide-bar {
  margin-bottom: 1rem;
  width: 25%;
  margin-left: 1rem;
}
.main-left-slide-bar {
  background-color: #418e30;

  width: 98%;
  color: #ffffff;
  height: 704px;
  /* margin: 1rem; */
  border-radius: 20px;
  text-align: center;
}
.left-slide-bar {
  width: 90%;
  margin: 1rem;
  padding: 0.5rem;
}

.left-slide-bar div {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-family: "Krona One";
  font-size: 20px;
  border-bottom: 1px solid;
  font-family: "Krona One", sans-serif;
}
.backgroundd9d9d9 {
  background-color: #d9d9d9;
  color: #418e30;
}
.flex {
  display: flex;
}
.ChangeEmailCom,
.mainFavourite {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  background: #fcfbfb;
  height: 400px;
  border-radius: 20px;
  box-shadow: 4px 2px 5px 0px #418e30;
}

.input-email-page {
  padding-left: 1rem;
  height: 55px;
  width: 96%;
  border-radius: 20px;
  border: 1px solid #e9f1ff;
}
.div-input-email-page {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ChangeEmailCom-div {
  margin-top: 1rem;
  margin-left: 1rem;
}
.favimg {
  border-radius: 50% !important;
  width: 65px;
  height: 75px;
  border: 2px solid #418e30;
}
.left-slide-bar div {
  cursor: pointer;
}
.favlist {
  border-bottom: 2px solid #d9d9d9;
}
.heartimage {
  background-color: #d92852;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ChangeEmailCom-div h3,
.Favourite-div h3 {
  font-family: "Krona One";
  width: 96%;
  border-bottom: 2px solid #418e30;
  padding-bottom: 20px;
  color: #418e30;
}
.Favourite-div{
  margin-top: 1rem !important;
  margin-left: 1rem !important;
}

.PaySubcriptionCom-spacing {
  margin-left: 6rem;
  column-gap: 65px;
}
.PaySubcriptionCom-spacing button {
  background-color: #418e30;
  border-radius: 10px;
  width: 300px;
  height: 50px;
  color: white;
  border: 0;
}

.PaySubcriptionCom-div .border-bottom-418e30 {
  border-bottom: 2px solid #418e30;
  width: 98%;
}
.PaySubcriptionCom-div .border-bottom-418e30 img {
  margin-left: 6rem;
  width: 35px;
  margin-top: 1.7rem;
  height: 35px;
}
.PaySubcriptionCom-div h3 {
  margin-left: 2rem;
  margin-top: 1.5rem;
  font-family: "Krona One";
  width: 96%;
  padding-bottom: 20px;
  color: #418e30;
}
.ChangeEmailCom-div .button-input {
  width: 300px;
  border-radius: 20px;
  height: 50px;
  background-color: #418e30;
  border: 0px;
  color: white;
  font-size: 20px;
}

.div-input-email-page img {
  position: absolute;
  width: 30px;
  top: 33%;
  position: absolute;
  width: 29px;
  height: 25px;
  right: 7%;
}
.PaySubcriptionCom {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 70%;
  margin-left: 1rem;
  margin-right: 1rem;
  background: #fcfbfb;
  border-radius: 20px;
  box-shadow: 4px 4px 4px 4px #418e30;
  height: 550px;
}
.PaycomponentCom {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  background: #fcfbfb;
  border-radius: 20px;
  box-shadow: 2px 2px 5px 2px #418e30;
  height: auto;
  padding: 50px 0px ;
}
.RightSliderCom {
  width: 24%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.RightSliderCom h3 {
  margin-left: 1rem;
  font-size: 22px;
  font-family: "Krona One";
  color: #418e30;
  border-bottom: 1px solid;
  text-transform: capitalize;
  margin-right: auto;
  width: 92%;
  text-align: center;
}
.h5-edit h5 {
  font-size: 14px;
  width: 148px;
  text-align: center;
  font-family: poppins;
  font-weight: 600;
}
.flex-wrap {
  flex-wrap: wrap;
}

@media screen and (max-width: 900px) {
  .RightSliderCom h3 {
    font-size: 18px;
  }
  .PaySubcriptionCom {
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .PaySubcriptionCom {
    width: 95%;
  }
  .RightSliderCom {
    width: 100%;
  }
  .PaySubcriptionCom-spacing,
  .PaySubcriptionCom-div .border-bottom-418e30 img {
    margin-left: 1rem;
  }
  .mainFavourite {
    width: 90% !important;
  }
}
@media screen and (max-width: 576px) {
  .Notification-Buttons {
    background-color: #418e30;
    border-radius: 20px;
    width: 267px;
  }
  .paysubdiv{
    width: 50% !important;
  }
  .PaySubcriptionCom-spacing.flex {
    flex-direction: column;
    align-items: center;
  }
  .PaySubcriptionCom-spacing button {
    width: 96%;
  }
  .PaySubcriptionCom {
    width: 91%;
  }
}

.AddCard h2{
  color: #418E30;
  font-size: 24px;
  font-weight: 500;
  padding: 10px;
  border-bottom: 2px solid #418E30;
}
.paymentcarddiv{
  background-color: #1bb66e36;
  border-radius: 16px !important;
}

.newcardhead,.cardfield{
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0px auto;
}
.tickicon {
  background-color: #1BB66E !important;
  border-radius: 50%;
  color: white;
  width: 25px;
  font-size: 18px;
  padding: 4px 0px;
  text-align: center;
}
.firstfield p{
  font-size: 12px !important;
}
.firstfield h6{
  font-size: 20px !important;
  font-weight: 600;
}
.cardimageicon{
  width: 6%;
}
.cardnumberinput{
  background-color: white;
  border-radius: 3px;
  border: 1px solid rgb(190, 188, 188);
  padding: 10px 2px;
}
.cardnumberinput input{
  border: none;
  outline: none;
}
.input1 ,.cvvinput{
  width: 30px;
  margin-right: 5px;
  outline: none;
}
.input1 input{
  border: 1px solid rgb(190, 188, 188); 
  outline: none;
  border-radius: 5px;
}
.cvv{
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.paysubscriptionbtn{
  background-color: #418E30;
  width: 300px;
  font-size: 20px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-weight: 600;
  padding: 10px 5px;
  color: white;
}
.paymentbtndiv{
  display: flex;
  justify-content: space-between;
}
.default{
  display: flex;
  justify-content: center;
  align-items: center;
}
.paycheckbox{
  width: 20px;
  height: 20px;
  accent-color: #418E30;
}
@media screen and (max-width:580px) {
  .cvv{
    justify-content: start !important;
  }
}
@media screen and (max-width:450px) {
  .cvv{
    justify-content: space-between !important;
  }
  .home_webbanner_banner{
    top: 35% !important;
  }
}
.paymentbutton{
  width: 90% !important;
  margin: 0px auto;
}


.toggle-switch {
  display: flex;
  border: 1px solid #ccc;
  background-color: #CCC;
  border-radius: 5px;
  overflow: hidden;
  width: 200px;
  border-radius: 30px;
}

.toggle-option {
  flex: 1;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 25px;
}

.toggle-option.active {
  background-color: green;
  color: white;
}

.toggle-option:not(.active) {
  background-color: #f0f0f0;
}

.toggle-option:first-child {
  border-right: 1px solid #ccc;
}
@media screen and (max-width:500px) {
  .backgroundImage .slick-slide {
    margin: 0px !important;
    height: 300px !important;
}
}
@media screen and (max-width:400px) {
  .backgroundImage .slick-slide {
    margin: 0px !important;
    height: 250px !important;
}
    .home_webbanner_banner .btn {
  width: 100px !important;
  font-size: 10px !important;
  margin-top: 5px !important;
}
}