.user-names-container {
    display: flex;
    flex-direction: column;
}

.user-name1 {
    font-size: 20px;
    margin-left: 10px;
    line-height: 20px;
}

.user-name2 {
    font-size: 20px;
    font-weight: bold;
    margin-left: 10px;
}



.res-routes {
    margin: 10px 0;
    border-radius: 10px;
    text-decoration: none;
    color: #000000 !important;
    font-size: 18px;
    font-family: 'Lato', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    width: 100%;
    display: block;
}

.res-routescontainer .links {
    display: flex;
    align-items: center;
    gap: 15px;
}

.btns {
    display: flex;
    flex-direction: column;
}

.hd-btns {
    background-color: white;
    border-radius: 10px;
    color: #000000 !important;
    font-size: 18px;
    font-family: 'Lato', sans-serif !important;
    font-weight: 800 !important;
    text-transform: uppercase;
    width: 100%;
    display: block;
}

.Activeroute {
    color: #418E30 !important;
}


.header-main-btns .logout {
    /* padding: 10px; */
    width: 0px !important;
    box-shadow: none;
    height: auto;
    background: none;
    /* border-radius: 100%; */
}

.userimage-head {
    width: 60px;
    border: solid 2px white;
    border-radius: 100%;
    overflow: hidden;
    height: 60px;
}

.userimage-head img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.userimage-head {
    width: 100%;
}

.userimg-head {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.header-main-btns .logout:hover {
    box-shadow: none;
    background: none;
}

/* @media screen and (min-width: 768px){
    .cat_select.ant-select {
        width: 116px;
    }
} */



.Home_categoriesimg {
    width: 69px !important;
    height: 69px;
    border-radius: 100%;
    overflow: hidden;
}

.cat_select.ant-select {
    border-radius: 30px;
    padding: 2px;
    width: 100% !important;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
}







/* Login page mistake correct css */


/* line no 429 App.css */
.CardMain .loginbtnout {
    width: 71%;
    height: 71%;
    background-color: #C4C4C4 !important;
}

/* line no 423 App.css */
.CardMain .loginbtn {
    width: 71%;
    background-color: #418E30 !important;
    margin-bottom: 10px;
}

/* line no 405 App.css */



/* Header correction Css */


/* line no 128 App.css */
.header-section-pages-each {
    padding: 8px;
    margin: 0px 40px !important;
}


/* line no 1209 App.css */
@media screen and (max-width:1097px) {
    .header-section-pages-each {
        margin: 0px 25px !important;
    }
}

@media screen and (max-width:977px) {
    .header-section-pages-each {
        margin: 0px 4px !important;
    }
}

@media screen and (max-width:809px) {
    .header-section-pages-each {
        margin: 0px 0px !important;
    }
}

.eachitem input[type="number"]::-webkit-inner-spin-button,
.eachitem input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
}



/* Features providers */
/* line no 478 index.css */



/* Category page Recommended section */





/* Buy product banner Css */
/* @media screen and (max-width: 992px) {
    .ProfileMainDiv .BuyProduct-pg {
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }
} */