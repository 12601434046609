@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed&family=Krona+One&family=Lato:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Actor&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manjari&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-float-btn-default
  .ant-float-btn-body
  .ant-float-btn-content
  .ant-float-btn-icon {
  color: #418e30;
}

.main_Home {
  overflow: hidden;
}

.home_webbanner_text {
  max-width: 690px;
  word-break: break-all;
  color: white;
}

/* .backgroundImage {
  background-image: url("./Assets//WebImages/AppBanner.png");
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  min-height: 500px;
  display: flex;
  align-items: center;
} */

.backgroundImage {
  position: relative;
  width: 100% !important;
  height: 500px;
}

.backgroundImage img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}

.home_webbanner_banner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.home_webbanner_banner .btn {
  background-color: #418e30 !important;
  color: white !important;
  border-radius: 30px !important;
  padding: 8px !important;
  font-size: 18px !important;
  width: 190px !important;
  text-align: center !important;
}

.banner .button:hover {
  background-color: #418e30;
}

.home_webbanner_text h1 {
  font-family: "Krona One", sans-serif;
  font-size: 2.5em;
}

.home_webbanner_text p {
  font-family: "Lato", sans-serif;
}

.home_webbanner_text .h5 {
  font-family: "Krona One", sans-serif !important;
  color: #418e30;
}

.home_webbanner_text h6 {
  font-family: "Lato", sans-serif;
}

.conatiner_Class .button {
  font-family: "Lato", sans-serif;
}

.navbaritems .signup,
.navbaritems .login,
.navbaritems .contactus,
.navbaritems .blogs,
.navbaritems .home,
.navbaritems .aboutus,
.navbaritems .services {
  font-family: "Lato", sans-serif !important;
}

.navlogo {
  width: 18%;
  max-height: 20%;
  margin-left: 40px;
}

.Link {
  text-decoration: none !important;
}

/* AppBannerTwo =====> */

.banner h6 {
  font-size: 14px;
}

.animated-progress {
  transition: width 1.5s ease-in-out;
}

.headings h6 {
  display: inline-block;

  margin: 0;
  font-size: 14px;
}

.right-border {
  border-right: 2px solid #d9d9d9;
}

.no-right-border {
  border-right: none !important;
}

.headings {
  display: flex;
  justify-content: space-between;
}

.headings span {
  font-weight: bold;
}

.banner .progress-bar-custom {
  background-color: #418e30 !important;
}

.banner button {
  background-color: transparent;
  border: 2px solid #418e30;
  border-radius: 50px;
  width: 100px;
  font-size: 12px;
  margin-top: 10px;
}

.banner button:hover {
  border: 2px solid #418e30 !important;
}

.Appbanner2 {
  background-color: white !important;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  position: relative;
  bottom: 80px;
}

/* Popular category */
.category_heading h1,
.category_heading h5 {
  font-family: "Krona One", sans-serif;
}

.category_heading h5 {
  color: #418e30;
}

.CategoriesBanner {
  margin: 25px 0px;
}

.category_Cards_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
}

.Home_categoriesimg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  overflow: hidden;
}

.Home_categoriesimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cat-45-home {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.home_categoryeach {
  width: 30%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 10px;
  border-radius: 20px;
  background-color: #f0f0f0;
  cursor: pointer;
}

.home_popolar_cat {
  width: 62%;
}

.home_subcat_search {
  padding: 3px 6px;
  background-color: #418e30;
  color: #fff;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.categorydiv_one_box1 {
  box-shadow: inset 6px 6px #418e30, inset 6px 0 #418e30, 0 8px 6px -6px #8f8f8f;
}

.categorydiv_one_box2 {
  box-shadow: inset 0 6px 0px #418e30, 0 8px 6px -6px #8f8f8f;
}

.categorydiv_one_box3 {
  box-shadow: inset -6px 6px #418e30, inset 0 6px #418e30,
    0 8px 6px -6px #8f8f8f;
}

.categorydiv_two {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 20px;
  padding-right: 60px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.home_categoryeach h5 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #418e30;
}

.cat_select.ant-select {
  border-radius: 30px;
  padding: 2px;
  width: 160px;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.category_cardMain {
  display: flex;
  justify-content: space-around;
}

.CategoriesBanner .button {
  background-color: #418e30;
  color: white;
  border-radius: 20px;

  padding: 4px;
  font-size: 15px;
  font-weight: bold;
  width: 190px;
  text-align: center;
  text-transform: inherit;
  box-shadow: 0px 3px 4px #418e30;
}

.CategoriesBanner .button:hover {
  background-color: #418e30;
}

/* :where(.css-dev-only-do-not-override-2q8sxy).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #418e30;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-select-single.ant-select-show-arrow .ant-select-selection-item,
:where(.css-dev-only-do-not-override-2q8sxy).ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-inline-end: 18px;
  color: white;
}

:where(.css-dev-only-do-not-override-2q8sxy).ant-select .ant-select-arrow {
  color: white !important;
} */

.SecRow {
  display: flex;
  justify-content: center;
}

.secdiv {
  display: flex;
  justify-content: space-around;
}

/* WhyChooseUs */
.ChooseUS {
  background-color: #418e30;
  width: 100%;
}

.chooseUS_div h2 {
  color: white;
  font-family: "Krona One", sans-serif !important;
}

.imgandtext h4 {
  font-size: 14px;
  margin-bottom: 0;
}

.imgandtext p {
  font-size: 13px;
}

.list_View {
  display: flex;
  align-items: center;
  color: white;
  gap: 15px;
  padding-bottom: 20px;
}

.whychoosecontent_text p {
  margin: 0;
}

.chooseUS_div h6 {
  font-family: "Poppins", sans-serif !important;
  color: white;
}

/* .imgDiv {
  width: 55%;
} */

.ChooseUs_BackGround {
  background-color: #418e30;
  padding: 80px;
}

.ChooseUsImg_BackGround {
  background-color: white;

  padding: 21px 20px 20px 20px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.Chooseus_ImageWrapper img {
  height: 34px;

  z-index: 100;
}

.Featured_Cards .Featured_Cards_each {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 44px;
  min-height: 442px;
  flex-direction: row;
  position: relative;
  width: 23%;
}

.Featured_Cards .Featured_Cards_merchant_descript {
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin-top: 56px; */
  /* padding-bottom: 10px; */
  border-bottom: 1px solid #d9d9d9;
}

.Featured_Cards_merchant_descript div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Featured_Cards_each .button {
  background-color: #418e30 !important;
  color: white;
  border-radius: 20px;
  padding: 4px;
  font-size: 15px;
  font-weight: bold;
  width: 190px;
  text-align: center;
  text-transform: inherit;
  box-shadow: 0px 3px 4px #418e30;
}

.Featured_Cards_each .button:hover {
  background-color: #418e30;
}

.Featured_Cards {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.Featured_Cards .Featured_Cards_name_container {
  background-color: #418e30;
  text-align: center;
  min-height: 166px;
  border-radius: 44px;
  position: relative;
  align-items: center;
  color: white;
  padding-top: 22px;
}

.Featured_Cards_merchantprofilebox {
  position: absolute;
  top: 45%;
  width: 100%;
  height: 146px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Featured_Cards_merchantimg {
  width: 249px;
  height: 100%;
  border-radius: 38px;
  overflow: hidden;
}

.Featured_Cards_name_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.Featured_Cards_each_body {
  margin-top: 80px;
}

.Featured_Cards_merchant_bio {
  display: flex;
  justify-content: center;
  padding: 10px 15px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Featured_Cards_merchant_bio span {
  word-break: break-all;
  font-size: 15px;
}

.view-btn {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 12px;
  text-align: center;
}

.heading1 {
  margin-top: 20px;
  font-family: "Krona One", sans-serif;
  color: #418e30;
  font-size: 15px;
}

.heading2 {
  font-family: "Krona One", sans-serif;
  color: #418e30;
  font-size: 40px;
}

.Featured_Cards_merchant_descript h2 {
  font-family: "Poppins", sans-serif !important;
  color: #418e30;
}

.Featured_Cards_merchant_descript span {
  font-family: "Lato", sans-serif;
  color: #848a94;
}

/* Profile */

.main_Profile {
  overflow: hidden;
}

.Background_trans {
  background-color: black !important;
  width: 100% !important;
  padding: 30px 0;
}

.Background_trans.container {
}

.profile_maindiv {
  display: flex;
  /* justify-content: center; */
}

.image_div {
  width: 30%;
}

.image_div h5 {
  color: white;
  line-height: 15px;
}

.profile_div {
  width: 70%;
  position: relative;
}

.profile_div p {
  position: relative;
  color: white !important;
  top: -22px;

  font-size: 12px;
}

/* 
.star {

  margin-top: -30px !important;

} */

.socialMedia {
  background-color: #418e30;
  width: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px 29px 8px 29px !important;
  border-radius: 9px;
}

.socialMedia .icon {
  display: inline-block;
  vertical-align: middle;
}

.Background_trans p {
  font-family: "Actor", sans-serif;
  font-size: 12px;
}

.Background_trans h1,
.Background_trans h4,
.Background_trans p {
  font-family: "Manjari", sans-serif;
}

.AllMedia .social_Media {
  background-color: rgb(65, 142, 48);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100px;
  height: 75px;
  cursor: pointer;
}

.AllMedia .social_Media a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.AllMedia .social_Media p {
  margin: 0 !important;
  margin-top: 4px !important;
}

.AllMedia .social_Media a p {
  margin: 0 !important;
  margin-top: 4px !important;
}

.share-select-dropdown {
  position: absolute;
  bottom: -64px;
  background-color: #f8f8f8;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  right: 10px;
}

.share-select-dropdown .share-list {
  display: flex;
  align-items: center;
  gap: 7px;
}

.social_Media img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.username {
  position: relative;
  font-size: 22px !important;
  margin: 2px 0;
}

.selectedproductimg {
  width: 350px;
  height: 282px;
}

.selectedproductimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
  overflow: hidden;
}

.selecteproUser-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.selecteproUser-img {
  width: 160px;
  height: 160px;
  border-radius: 100%;
  border: 2px solid #418e30;
  overflow: hidden;
}

.ProfileMainDiv .star {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.Background_trans h5,
.Background_trans h6 {
  font-family: "Poppins", sans-serif !important;
}

.AllMedia {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

/* Service_banner */
.Service_banner h3 {
  font-family: "Krona One", sans-serif;
}

/* PeopleComment */
.backGround_PpleComt {
  background-color: #f8f8f8;
  padding: 30px;
  border-radius: 49px;
}

.reviewuser_star svg {
  margin: 0 !important;
}

.backGround_PpleComt h4 {
  color: #423f55;
  font-family: "Krona One", sans-serif;
}

/* About_me */
.About_me h2 {
  font-family: "Krona One", sans-serif;
  color: #423f55;
}

.About_me p {
  font-family: "Lato", sans-serif;
  color: #546e7a !important;
  font-size: 18px !important;
}

/* aboutUs */
.about_US {
  overflow: hidden;
}

.about_US .p {
  font-family: "Poppins", sans-serif !important;
  color: #54ac2a;
}

.about_US h3 {
  font-family: "Krona One", sans-serif;
}

.about_US h6 {
  font-family: "Poppins", sans-serif !important;
  line-height: 35px;
  font-weight: bold;
}

.about_US h5 {
  font-family: "Poppins", sans-serif !important;
  color: #525252;
  font-size: 18px;
}

.about_US .BackGround {
  background-color: #f5f5f5;
}
.about_imgdiv{
  height: 60vh;
}
.aboutuspara{
  font-size: 16px !important;
}
.about_img{
  height: 100% !important;
}
/* .aboutus_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
} */

.BackGround img {
  /* width: 100%  !important; */
  height: 380px;
  border-radius: 10px !important;
  object-fit: cover;
}

.about_US h2 {
  font-family: "Krona One", sans-serif;
  margin-top: 50px;
}

.team_Background {
  background-color: #f5f5f5;
  padding: 20px 5px;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about_us_slider_container .about_us_slider_each {
  width: 100% !important;
}

.team_Background .teams_profile {
  width: 6rem;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.team_Background .teams_profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.team_Background h6,
.team_Background p {
  font-family: "Poppins", sans-serif !important;
  margin: 6px 0;
  text-align: center;
  line-height: 1;
}

.slick-dots li button:before {
  content: "〇" !important;
  font-size: 14px !important;
  color: #418e30 !important;
}

.about_inpt_main {
  padding: 10px;
  background: #fff;
  border-radius: 10px;
}

.about_inp {
  display: flex;
}

.about-input {
  width: 100%;
  outline: none;
  border: none;
}

.aboutusbtn {
  background-color: #000000 !important;
  border-radius: 10px !important;
  width: 40%;
}

.BackGround_Green {
  background-color: #418e30;
  padding: 28px;
  border-radius: 15px;
  color: white !important;
}

.BackGround_Green p {
  font-family: "Poppins", sans-serif !important;
}

.PeopleComment .days {
  font-size: 14px;
  color: #90a4ae;
  margin-left: 130px;
  margin-bottom: 30px;
}

.PeopleComment .button {
  background-color: #418e30;
  color: white;
  border-radius: 8px;
  padding: 4px;
  font-size: 15px;
  font-weight: bold;
  width: 190px;
  text-align: center;
  text-transform: inherit;
  box-shadow: 0px 3px 4px #418e30;
}

.PeopleComment .button:hover {
  background-color: #418e30;
}

/* Category  full page*/

.category .categoryOne {
  background-color: #fcfbfb;
  padding: 56px 34px 46px 22px;
  box-shadow: 0.5px 4px 5px 1px #418e30 !important;
  border-radius: 52px !important;
  max-height: 400px;
}

.category .cat-input {
  width: 100%;
  font-family: "Poppins", sans-serif !important;
}

.category .categoryTwo {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  max-height: 580px;
  overflow-y: auto;
}

.category .categoryTwo::-webkit-scrollbar {
  width: 3px;
}

.category .categoryTwo::-webkit-scrollbar-thumb {
  background-color: #d9d9d9;
  height: 5px;
}

.categorycardsimg {
  width: 100%;
  height: 120px;
}

.categorycardsimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.category .select {
  background-color: rgb(65, 142, 48);
  border-radius: 5px !important;
  /* padding: 15px;
    padding-right: 114px !important; */
}

.category .button1 {
  background-color: #418e30;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  text-align: center;
  padding: 10px;
}

.category .button1:hover {
  border: 2px solid #b2bbce;
  color: black;
  border-radius: 8;
}

.category .button2 {
  background-color: transparent;
  color: black;
  border-radius: 8px;
  border: 2px solid #b2bbce;
  font-size: 12px;

  text-align: center;
}

.category .button2:hover {
  background-color: #418e30;
  color: white;
  border-radius: 8px;
}

.category .backgroundtwo-cards {
  background-color: #f8f9fb;
  display: flex;
  min-width: 178px;
  max-width: 186px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  height: fit-content;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #418e30 !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(34 255 66 / 16%) !important;
}

.category .button {
  margin-left: 0 !important;
  font-family: "Manrope", sans-serif;
}

.category p {
  font-family: "Manrope", sans-serif;
  margin: 0;
  text-align: center;
}

/* privacypolicy */

.privacypolicy p {
  font-family: "Poppins", sans-serif !important;
  font-size: 1rem;
}

/* rating */

.rating {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ratCard_BackGrnd {
  margin-top: 50px;
  background-color: #f0efef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 43px;
  border-radius: 13px;
  min-width: 60%;
}

.ratCard_BackGrnd textarea {
  height: 219px;
  border-radius: 9px;
  border: none !important;
  padding: 15px;
  background-color: #f5f6fa;
  outline: none;
}

.ratCard_BackGrnd textarea:focus-visible {
  border: none !important;
}

.ratCard_BackGrnd .button {
  background-color: #418e30;
  color: white;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
  width: 40%;
  border-radius: 9px;
  text-align: center;
}

.ratCard_BackGrnd .button:hover {
  background-color: #418e30;
}

.ratCard_BackGrnd h5 {
  font-family: "Inter", sans-serif;
}
.Category_search_cat{
    position: relative;
}
.Category_search_cat input::placeholder{
  color: white;
}
@media screen and (max-width:1350px) {
  .aboutuspara{
    font-size: 12px !important;
  }
}
@media screen and (max-width:850px) {
    .aboutuspara{
      font-size: 11px !important;
    }
}
@media screen and (max-width:767px) {
  .aboutuspara{
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 767px) {
  /* mobile */

  .home_webbanner_text h1 {
    font-size: 16px;
  }

  /* 
  .home_banner_div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 15px;
  } */

  .home_webbanner_text p {
    font-size: 12px;
    width: 300px;
  }

  .right-border {
    border-right: none !important;
    border-bottom: 2px solid #d9d9d9;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .Comment {
    border-right: none !important;

    border-bottom: 2px solid #418e30;
    /* Adjust height as needed */
  }

  .PeopleComment .days {
    margin-left: 47px;
    margin-bottom: 28px;
  }

  .background {
    display: flex;
    width: 132px;
  }

  .category .categoryOne {
    margin-bottom: 37px;
    padding: 55px 33px 46px 22px;
  }

  .home_categoryeach {
    width: 100%;
  }

  .category .backgroundtwo-cards {
    min-width: 100% !important;
  }

  .AllMedia .social_Media {
    width: 69px !important;
  }
}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
  /* Add your tablet styles here */
  .right-border {
    border-right: none !important;
    border-bottom: 2px solid #d9d9d9;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .Comment {
    border-right: none !important;
    border-bottom: 2px solid #418e30;
    /* Adjust height as needed */
  }

  .category .button {
    margin: 0 !important;
    margin-top: 10px !important;
  }
}

/* @media (min-width: 1400px) {
  .container {
    max-width: 1400px;
  }
} */

/* extra container  */

@media screen and (min-width: 768px) {
  .Background_trans .container {
    max-width: 983px !important;
  }

  .Service_banner.container {
    max-width: 983px !important;
  }
}

@media screen and (min-width: 1200px) {
  .Background_trans .container {
    max-width: 1289px !important;
  }

  .Service_banner.container {
    max-width: 1289px !important;
  }
}

.Notification-Lorem {
  width: 98%;
  border-bottom: 1px solid #3a3a3c;
}
.Notification-Lorem p {
  width: 80%;
}
.justify-content-space-between {
  justify-content: space-between;
}
.time-Notification {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.time-Notification p {
  width: 100%;
  font-size: 12px;
}
.time-Notification img {
  position: relative !important;
  width: 0 !important;
  width: 18px !important;
  height: 17px !important;
  top: 0 !important;
  right: 0 !important;
}

.justify-content-space-evenly{
  justify-content: space-evenly;
}

.color-54AC2A{
  color: #54AC2A;
}